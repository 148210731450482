<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card :loading="loading">
        <v-toolbar color="green" dark>
          <v-toolbar-title
            >Order Detail
            <span v-if="!empty(orderDetail)"
              >(Order No: {{ orderDetail.id }})</span
            ></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-content-save-all-outline</v-icon>
          </v-btn>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-layout row wrap class="ma-5 pa-5" v-if="!empty(orderDetail)">
          <v-container>
            <v-row>
              <v-flex xs12 sm12 md12 lg8 xl8 class="pt-5">
                <v-btn
                  icon
                  @click="showActivityLog = true"
                  class="pr"
                  color="warning"
                >
                  <v-icon>mdi-history</v-icon>
                </v-btn>
                <order-header :orderDetail="orderDetail"></order-header>
                <order-steps :current_stage="current_stage"></order-steps>
                <v-row>
                  <v-flex
                    xs12
                    sm12
                    md12
                    lg12
                    xl12
                    class="pt-2 pb-2 text-center"
                  >
                    <v-btn
                      class="pl"
                      @click="addToBasket"
                      v-if="orderDetail.status == 'Pending'"
                      :disabled="current_stage >= 1 || loading"
                      color="green"
                      >Add to Basket</v-btn
                    >
                    <v-btn
                      class="ml-5 pl"
                      v-if="orderDetail.status == 'Added to Basket'"
                      @click="generateQuote"
                      :disabled="current_stage != 1 || loading"
                      color="green"
                      >Generate Quotation</v-btn
                    >
                    <v-btn
                      class="ml-5 pl"
                      v-if="orderDetail.status == 'Payment Verified'"
                      @click="dispatchOrder"
                      :disabled="current_stage != 4 || loading"
                      color="green"
                      >Dispatch/Bill Created</v-btn
                    >
                    <v-btn
                      class="ml-5 pl"
                      v-if="orderDetail.status == 'Dispatched'"
                      @click="takeDelivery"
                      :disabled="current_stage != 5 || loading"
                      color="green"
                      >Take for Delivery</v-btn
                    >
                    <v-btn
                      class="ml-5 pl"
                      v-if="orderDetail.status == 'Out for Delivery'"
                      @click="confirmDelivery"
                      :disabled="current_stage != 6 || loading"
                      color="green"
                      >Delivered</v-btn
                    >
                    <v-chip class="ma-2" color="red" text-color="white">
                      <strong
                        >DELIVERY TIME SLOT:
                        {{ orderDetail.delivery_time_slot }}</strong
                      >
                    </v-chip>
                    <v-btn
                      class="ml-5 pr"
                      @click="confirmCancel"
                      :disabled="!allowCancel || loading"
                      color="error"
                      >Cancel Order</v-btn
                    >
                    <v-btn
                      class="ml-5 pr"
                      v-if="orderDetail.status == 'Quotation Sent'"
                      @click="showAttachmentForm = true"
                      :disabled="
                        orderDetail.status != 'Quotation Sent' || loading
                      "
                      color="primary"
                      >Attach Payment Slip</v-btn
                    >
                  </v-flex>
                </v-row>
                <order-items
                  class="mt-3"
                  :orderDetail="orderDetail"
                  v-on:updateStatus="updateStatus"
                  v-on:itemSelectionChanged="itemSelectionChanged"
                  v-on:itemAdded="itemAdded"
                ></order-items>
              </v-flex>
              <v-flex xs12 sm12 md4 lg4 xl4>
                <bank-statement :order="order"></bank-statement>
              </v-flex>
            </v-row>
          </v-container>
        </v-layout>
        <v-divider></v-divider>
      </v-card>
      <v-navigation-drawer
        v-model="showActivityLog"
        width="500"
        absolute
        right
        temporary
        class="pa-3"
      >
        <v-btn icon @click="showActivityLog = false" class="pr">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <order-activity-log
          v-on:viewQuote="viewQuote"
          v-on:viewSlip="viewSlip"
          :orderDetail="orderDetail"
        ></order-activity-log>
      </v-navigation-drawer>
      <view-quotation
        :file="quotation_path"
        :show="showViewQuotation"
        :orderDetail="orderDetail"
        v-on:sendToCustomer="sendToCustomer"
        v-on:close="
          quotation_path = null;
          showViewQuotation = false;
        "
      ></view-quotation>
      <view-slip
        :file="slip_path"
        :show="showViewSlip"
        :orderDetail="orderDetail"
        v-on:verified="paymentVerified"
        v-on:close="
          slip_path = null;
          showViewSlip = false;
        "
      ></view-slip>
    </v-dialog>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="green" dark>
        <v-card-text>
          {{ loadingText }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showOrderCancel"
      hide-overlay
      persistent
      width="600"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="red" dark>
          <v-toolbar-title>Cancel Order</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="onConfirmCancel" :disabled="reason.length <= 0">
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
          <v-btn icon @click="cancelCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="align-center">
          <v-form ref="form" lazy-validation>
            <v-textarea
              solo
              style="width: 100%"
              v-model="reason"
              label="Reason for Cancellation"
            ></v-textarea>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAttachmentForm"
      hide-overlay
      persistent
      width="600"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="blue" dark>
          <v-toolbar-title>Attach & Verify Payment Slip</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            icon
            @click="onConfirmAttachment"
            :disabled="attachment.length <= 0"
          >
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
          <v-btn icon @click="cancelAttachment">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="align-center">
          <div class="mx-auto text-center pt-8">
            <div class="mb-6 d-block">
              <v-flex sm16 md12 lg12 xl12>
                <v-file-input
                  style="border-bottom: none !important"
                  v-model="attachment"
                  color="light-blue accent-4"
                  counter
                  label="Payment Slip"
                  placeholder="Click to browse"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                </v-file-input>
              </v-flex>
            </div>
            <v-btn
              pl-0
              ml-0
              large
              :disabled="empty(attachment)"
              @click="onConfirmAttachment"
              class="accent"
              >Update</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirmation-dialog
      ref="dispatchConfirmationDialog"
      message="Are you sure you want to Dispatch this Order?"
      @onConfirm="onDispatchOrder"
    >
    </confirmation-dialog>
    <confirmation-dialog
      ref="orderDeliveryConfirmationDialog"
      message="Confirm you are taking this order for delivery"
      @onConfirm="onTakeDelivery"
    >
    </confirmation-dialog>
    <confirmation-dialog
      ref="confirmDeliveryConfirmationDialog"
      message="Confirm the Order is delivered to the Customer. A SMS will be sent to customer."
      @onConfirm="onConfirmDelivery"
    >
    </confirmation-dialog>
    <confirmation-dialog
      ref="addToBasketDeliveryConfirmationDialog"
      message="There are some Items missing in the basket, are you sure you want to proceed?"
      @onConfirm="upadteBasket"
    >
    </confirmation-dialog>

    <v-overlay :value="loading"></v-overlay>
  </v-row>
</template>
<script>
import pdf from "vue-pdf";
import OrderActivityLog from "./OrderActivityLog";
import OrderHeader from "./OrderHeader.vue";
import OrderItems from "./OrderItems.vue";
import OrderSteps from "./OrderSteps.vue";
import ViewQuotation from "./ViewQuotation.vue";
import ViewSlip from "./ViewSlip.vue";
import getBankStatement from "./BankStatement.vue";
import BankStatement from "./BankStatement.vue";
export default {
  watch: {
    show: function (val, newVal) {
      this.dialog = this.show;
    },
    order: function (val, newVal) {
      this.getOrderDetails();
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    order: null,
    permissions: null,
    path: null,
  },
  created() {},
  components: {
    OrderActivityLog,
    OrderItems,
    OrderSteps,
    OrderHeader,
    ViewQuotation,
    ViewSlip,
    BankStatement,
  },
  data() {
    return {
      showActivityLog: false,
      loading: false,
      dialog: false,
      orderDetail: null,
      current_stage: 0,
      quotation_path: "",
      showViewQuotation: false,
      showViewSlip: false,
      slip_path: "",
      loadingText: "loading ...",
      selectedItems: [],
      allowCancel: false,
      showOrderCancel: false,
      reason: "",
      showAttachmentForm: false,
      attachment: "",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    getOrderDetails() {
      if (this.empty(this.order)) return;
      this.loadingText = "loading order details ...";
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
      };
      this.post("web/orders/detail", f)
        .then((response) => {
          this.loading = false;
          this.orderDetail = response.data;
          console.log(this.permissions.permissions);
          this.allowCancel =
            this.getObjectFromArray(
              this.permissions.permissions,
              "permission",
              "Cancel"
            ).length > 0;
          this.setCurrentStage();
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    updateStatus(status, selectedItems) {
      this.loadingText = "updating ...";
      this.loading = true;
      var i = [];
      this.selectedItems.forEach((element) => {
        i.push(element.id);
      });
      let f = {
        order_id: this.order.order_id,
        items: i,
        status: status,
      };
      this.post("web/orders/update-item-status", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.orderDetail = response.data.data;
            this.setCurrentStage();
            this.showInfo("Items Updated");
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    itemSelectionChanged(items) {
      this.selectedItems = items;
    },
    itemAdded(order) {
      this.orderDetail = order;
    },
    addToBasket() {
      if (this.selectedItems.length <= 0) {
        this.showError("Select at least one item to add basket");
        return;
      }
      if (this.selectedItems.length < this.orderDetail.items.length) {
        this.$refs.addToBasketDeliveryConfirmationDialog.openDialog();
      } else {
        this.upadteBasket();
      }
    },
    upadteBasket() {
      this.$refs.addToBasketDeliveryConfirmationDialog.close();
      this.loadingText = "updating ...";
      this.loading = true;
      var i = [];
      this.selectedItems.forEach((element) => {
        i.push(element.id);
      });
      let f = {
        order_id: this.order.order_id,
        items: i,
        status: status,
      };
      this.post("web/orders/update-basket", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.orderDetail = response.data.data;
            this.setCurrentStage();
            this.showInfo("Items Updated");
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    setCurrentStage() {
      if (this.orderDetail.status == "Added to Basket") this.current_stage = 1;
      if (this.orderDetail.status == "Quotation Sent") this.current_stage = 2;
      if (this.orderDetail.status == "Payment Received") this.current_stage = 3;
      if (this.orderDetail.status == "Payment Verified") this.current_stage = 4;
      if (this.orderDetail.status == "Dispatched") this.current_stage = 5;
      if (this.orderDetail.status == "Out for Delivery") this.current_stage = 6;
      if (this.orderDetail.status == "Delivered") this.current_stage = 7;
    },
    generateQuote() {
      this.loadingText = "generating quote ...";
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
      };
      this.post("web/orders/download-quote", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.quotation_path = response.data.data;
            this.showViewQuotation = true;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    viewQuote(quotation_path) {
      this.quotation_path = quotation_path;
      this.showViewQuotation = true;
    },
    viewSlip(path) {
      this.slip_path = path;
      this.showViewSlip = true;
    },
    paymentVerified(data) {
      this.orderDetail = data;
      this.slip_path = "";
      this.showViewSlip = false;
    },
    sendToCustomer(quotation_path) {
      this.loadingText = "sending quotation ...";
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
        quotation_path: quotation_path,
      };
      this.post("web/orders/send-quote", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.showInfo("Quotation sent to customer");
            this.orderDetail = response.data.data;
            this.showViewQuotation = false;
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    dispatchOrder() {
      this.$refs.dispatchConfirmationDialog.openDialog();
    },
    onDispatchOrder() {
      this.loadingText = "dispatching the order ...";
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
      };
      this.post("web/orders/dispatch", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.showInfo("Order Dispatched");
            this.$refs.dispatchConfirmationDialog.close();
            this.orderDetail = response.data.data;
            this.setCurrentStage();
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    takeDelivery() {
      this.$refs.orderDeliveryConfirmationDialog.openDialog();
    },
    onTakeDelivery() {
      this.loadingText = "updating status ...";
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
      };
      this.post("web/orders/go-for-delivery", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.$refs.orderDeliveryConfirmationDialog.close();
            this.orderDetail = response.data.data;
            this.setCurrentStage();
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    confirmDelivery() {
      this.$refs.confirmDeliveryConfirmationDialog.openDialog();
    },
    onConfirmDelivery() {
      this.loadingText = "completing order ...";
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
      };
      this.post("web/orders/order-delivered", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.$refs.confirmDeliveryConfirmationDialog.close();
            this.orderDetail = response.data.data;
            this.setCurrentStage();
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    confirmCancel() {
      this.showOrderCancel = true;
    },
    cancelCancel() {
      this.reason = "";
      this.showOrderCancel = false;
    },
    onConfirmCancel() {
      this.loadingText = "cancelling order ...";
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
        path: this.path,
        remarks: this.reason,
      };
      this.post("web/orders/order-cancel", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.showOrderCancel = false;
            this.orderDetail = response.data.data;
            this.showInfo("Order cancelled");
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    cancelAttachment() {
      this.attachment = "";
      this.showAttachmentForm = false;
    },
    async onConfirmAttachment() {
      this.loadingText = "cancelling order ...";
      this.loading = true;
      var file = null;
      await this.getBase64(this.attachment)
        .then((res) => {
          file = res;
        })
        .catch((e) => {
          //console.log(e.message);
        });
      let f = {
        order_id: this.order.order_id,
        path: this.path,
        attachment: file,
      };
      this.post("web/orders/attach-very-payment", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.showAttachmentForm = false;
            this.orderDetail = response.data.data;
            this.showInfo("Payment Slip attached & Verified.");
            this.close();
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
  },
};
</script>