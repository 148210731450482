<template>
  <div class="mb-15">
    <div class="main mb-15">
      <emb-sidebar-panel :showButton="true" class="mb-12"></emb-sidebar-panel>
      <dashboard></dashboard>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import Dashboard from "./OrderManagement/Dashboard.vue";
export default {
  created() {
    this.fcmRegister();
  },
  components: {
    Dashboard,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.getMenu();
    this.$store.dispatch("setMenu", []);
  },
  computed: {
    ...mapGetters(["showRightSideDrawer", "fcmToken", "user"]),
  },
  methods: {
    ...mapMutations(["resetState"]),
    getMenu() {
      this.post("web/get-menu")
        .then((response) => {
          this.menu = response.data;
          this.$store.dispatch("setMenu", response.data);
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
  },
};
</script>