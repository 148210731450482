<template>
  <v-row class="ml-10">
    <v-card :loading="loading">
      <v-card-actions>
        <v-btn
          color="deep-purple lighten-2"
          :disabled="loading"
          text
          @click="getBankStatement"
        >
          Reload the Bank Statement
        </v-btn>
      </v-card-actions>
      <v-data-table
        :headers="headers"
        :items="transactions"
        item-key="id"
        class="elevation-1"
      >
      </v-data-table>
    </v-card>
  </v-row>
</template>
<script>
export default {
  watch: {
    show: function (val, newVal) {
      this.dialog = this.show;
    },
    order: function (val, newVal) {
      this.getBankStatement();
    },
  },
  props: {
    order: null,
  },
  created() {},
  components: {},
  data() {
    return {
      loading: false,
      dialog: false,
      transactions: [],
      headers: [
        { text: "Date", align: "start", value: "narrative2" },
        { text: "Name", align: "start", value: "narrative3" },
        { text: "Amount", align: "end", value: "amount" },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    getBankStatement() {
      if (this.empty(this.order)) return;
      this.loadingText = "loading Bank Statement ...";
      this.loading = true;
      this.post("web/orders/get-bml-statement")
        .then((response) => {
          //console.log(response.data.payload.history);
          this.loading = false;
          this.transactions = response.data.payload.history;
        })
        .catch((error) => {
          this.loading = false;
          this.showError("Could not load the BML Statement, try again.");
        });
    },
  },
};
</script>