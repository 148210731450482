<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="800"
      transition="dialog-bottom-transition"
    >
      <v-card :loading="loading">
        <v-toolbar color="green" dark>
          <v-toolbar-title
            >View Payment Slip
            <span v-if="!empty(orderDetail)"
              >(Order No: {{ orderDetail.id }})</span
            ></v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-layout row wrap class="ma-5 pa-5">
          <v-container>
            <v-row>
              <v-flex
                xs12
                sm12
                md12
                lg12
                xl12
                class="pt-5 mt-5"
                style="text-align: center"
              >
                <a :href="host_web + 'storage/' + path" target="_blank"
                  ><v-btn color="success" class="mr-2">
                    Download Slip
                  </v-btn></a
                >
                <v-btn
                  color="warning"
                  @click="verifyPayment"
                  :disabled="loading"
                >
                  Verify Payment
                </v-btn>
                <v-slider
                  v-model="width"
                  class="align-self-stretch mt-5"
                  min="400"
                  max="2000"
                  step="1"
                ></v-slider>

                <v-img
                  class="mt-5"
                  :aspect-ratio="16 / 9"
                  :width="width"
                  :src="host_web + 'storage/' + path"
                ></v-img>
              </v-flex>
            </v-row>
          </v-container>
        </v-layout>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  watch: {
    file: function (val, newVal) {
      this.path = this.file;
    },
    show: function (val, newVal) {
      this.dialog = this.show;
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    file: null,
    orderDetail: null,
  },
  created() {},
  components: {},
  data() {
    return {
      path: "",
      width: 600,
      loading: false,
      dialog: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    verifyPayment() {
      this.loading = true;
      let f = {
        order_id: this.orderDetail.id,
        slip_path: this.file,
      };
      this.post("web/orders/verify-payment", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.$emit("verified", response.data.data);
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    download() {
      window.document.location.href = this.path;
    },
  },
};
</script>