<template>
  <v-layout row wrap class="pl-5 pr-5">
    <v-flex xs12 sm12 md12 lg3 xl3 class="mb-5" v-if="viewPendingPreparations">
      <order-list
        v-on:statusChanged="statusChanged"
        v-on:showOrderDetail="orderDetailViewLoaded"
        title="Pending Prepartions"
        :items="pendingOrders"
        path="prepare-orders"
        :permissions="permissionsPendingPreparations"
      ></order-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg3 xl3 v-if="viewPendingQuotations">
      <order-list
        v-on:statusChanged="statusChanged"
        v-on:showOrderDetail="orderDetailViewLoaded"
        title="Pending Quotation"
        :items="pendingQuotations"
        path="prepare-quotations"
        :permissions="permissionsPendingQuotations"
      ></order-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg3 xl3 v-if="viewPendingPayments">
      <order-list
        title="Pending Payment"
        v-on:statusChanged="statusChanged"
        v-on:showOrderDetail="orderDetailViewLoaded"
        :items="pendingPayment"
        path="orders-pending-payment"
        :permissions="permissionsPendingPayments"
      ></order-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg3 xl3 v-if="viewPendingPaymentVerifications">
      <order-list
        title="Pending Verification"
        v-on:statusChanged="statusChanged"
        v-on:showOrderDetail="orderDetailViewLoaded"
        :items="pendingVerification"
        path="payment-verifications"
        :permissions="permissionsPendingPaymentVerifications"
      ></order-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg3 xl3 v-if="viewPendingDispatch">
      <order-list
        title="Pending Dispatch"
        v-on:statusChanged="statusChanged"
        v-on:showOrderDetail="orderDetailViewLoaded"
        :items="pendingDispatch"
        path="dispatch-orders"
        :permissions="permissionsPendingDispatch"
      ></order-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg3 xl3 v-if="viewPendingDelivery">
      <order-list
        title="Pending Delivery"
        v-on:statusChanged="statusChanged"
        v-on:showOrderDetail="orderDetailViewLoaded"
        :items="pendingDelivery"
        path="deliver-orders"
        :permissions="permissionsPendingDelivery"
      ></order-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg3 xl3 v-if="viewOutOnDelivery">
      <order-list
        title="Out on Delivery"
        v-on:statusChanged="statusChanged"
        v-on:showOrderDetail="orderDetailViewLoaded"
        :items="outForDelivery"
        path="orders-on-delivery"
        :permissions="permissionsOutOnDelivery"
      ></order-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg3 xl3>
      <!--<conversations
        title="Conversations"
        :items="conversations"
      ></conversations>-->
    </v-flex>
  </v-layout>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
//import Conversations from "./Conversations.vue";
import OrderList from "./OrderList.vue";

export default {
  created() {
    this.timer = setInterval(this.getOrdersDashbaord, 60000);
  },
  components: { OrderList },
  data() {
    return {
      loading: false,
      selected: [2],
      selection: null,
      pendingOrders: null,
      pendingQuotations: null,
      pendingPayment: null,
      pendingVerification: null,
      pendingDispatch: null,
      pendingDelivery: null,
      outForDelivery: null,
      conversations: null,
      orderDetailLoaded: false,
      viewPendingPreparations: false,
      viewPendingQuotations: false,
      viewPendingPayments: false,
      viewPendingPaymentVerifications: false,
      viewPendingDispatch: false,
      viewPendingDelivery: false,
      viewOutOnDelivery: false,
      permissions: [],
      permissionsPendingPreparations: null,
      permissionsPendingQuotations: null,
      permissionsPendingPayments: null,
      permissionsPendingPaymentVerifications: null,
      permissionsPendingDispatch: null,
      permissionsPendingDelivery: null,
      permissionsOutOnDelivery: null,
    };
  },
  mounted() {
    this.getOrdersDashbaord();
  },
  computed: {
    ...mapGetters(["showRightSideDrawer", "fcmToken", "user"]),
  },
  methods: {
    orderDetailViewLoaded(status) {
      this.orderDetailLoaded = status;
    },
    statusChanged() {
      this.orderDetailLoaded = false;
      this.getOrdersDashbaord();
    },
    getOrdersDashbaord() {
      console.log(this.orderDetailLoaded);
      if (this.orderDetailLoaded) return;
      this.post("web/orders/dashboard")
        .then((response) => {
          this.pendingOrders = response.data.pending;
          this.pendingQuotations = response.data.pending_quotations;
          this.pendingPayment = response.data.awaitingpayments;
          this.pendingVerification = response.data.paymentreceived;
          this.pendingDispatch = response.data.paymentverified;
          this.pendingDelivery = response.data.dispatched;
          this.outForDelivery = response.data.outfordelivery;
          this.permissions = response.data.permissions;

          this.viewPendingPreparations =
            this.getObjectFromArray(
              this.permissions,
              "module",
              "Prepare Orders"
            )[0].permissions.length > 0;
          this.viewPendingQuotations =
            this.getObjectFromArray(
              this.permissions,
              "module",
              "Prepare Quotations"
            )[0].permissions.length > 0;
          this.viewPendingPayments =
            this.getObjectFromArray(
              this.permissions,
              "module",
              "Orders Pending Payment"
            )[0].permissions.length > 0;
          this.viewPendingPaymentVerifications =
            this.getObjectFromArray(
              this.permissions,
              "module",
              "Payment Verifications"
            )[0].permissions.length > 0;
          this.viewPendingDispatch =
            this.getObjectFromArray(
              this.permissions,
              "module",
              "Dispatch Orders"
            )[0].permissions.length > 0;
          this.viewPendingDelivery =
            this.getObjectFromArray(
              this.permissions,
              "module",
              "Deliver Orders"
            )[0].permissions.length > 0;
          this.viewOutOnDelivery =
            this.getObjectFromArray(
              this.permissions,
              "module",
              "Orders On delivery"
            )[0].permissions.length > 0;

          this.permissionsPendingPreparations = this.getObjectFromArray(
            this.permissions,
            "module",
            "Prepare Orders"
          )[0];
          this.permissionsPendingQuotations = this.getObjectFromArray(
            this.permissions,
            "module",
            "Prepare Quotations"
          )[0];
          this.permissionsPendingPayments = this.getObjectFromArray(
            this.permissions,
            "module",
            "Orders Pending Payment"
          )[0];
          this.permissionsPendingPaymentVerifications = this.getObjectFromArray(
            this.permissions,
            "module",
            "Payment Verifications"
          )[0];
          this.permissionsPendingDispatch = this.getObjectFromArray(
            this.permissions,
            "module",
            "Dispatch Orders"
          )[0];
          this.permissionsPendingDelivery = this.getObjectFromArray(
            this.permissions,
            "module",
            "Deliver Orders"
          )[0];
          this.permissionsOutOnDelivery = this.getObjectFromArray(
            this.permissions,
            "module",
            "Orders On delivery"
          )[0];
        })
        .catch((error) => {
          //this.handleErrors(error);
        });
    },
  },
};
</script>