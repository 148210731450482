<template>
  <v-stepper alt-labels elevation="0" v-model="current_stage">
    <v-stepper-header>
      <v-stepper-step step="1" :complete="current_stage >= 1" color="green">
        Add Items to Basket
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" :complete="current_stage >= 2" color="green">
        Send Quotation</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step step="3" :complete="current_stage >= 3" color="green">
        Receive Payment
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4" :complete="current_stage >= 4" color="green">
        Verify Payment
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="5" :complete="current_stage >= 5" color="green">
        Make Bill/Dispatch
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="6" :complete="current_stage >= 6" color="green">
        Go for Delivery
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="7" :complete="current_stage >= 7" color="green">
        Delivered
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
export default {
  props: {
    current_stage: {
      type: Number,
      default: 0,
    },
  },
};
</script>