<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="400"
      transition="dialog-bottom-transition"
    >
      <v-card :loading="loading">
        <v-toolbar color="green" dark>
          <v-toolbar-title
            >View Quotation
            <span v-if="!empty(orderDetail)"
              >(Order No: {{ orderDetail.id }})</span
            ></v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-layout row wrap class="ma-5 pa-5">
          <v-container>
            <v-row>
              <v-flex
                xs12
                sm12
                md12
                lg12
                xl12
                class="pt-5"
                style="max-width: 400px; text-align: center"
              >
                <a :href="quotation_path" target="_blank"
                  ><v-btn color="success" class="mr-2">
                    Download Quote
                  </v-btn></a
                >
                <v-btn color="warning" @click="sendToCustomer">
                  Send to Customer
                </v-btn>
                <pdf :src="quotation_path"></pdf>
              </v-flex>
            </v-row>
          </v-container>
        </v-layout>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import pdf from "vue-pdf";
export default {
  watch: {
    file: function (val, newVal) {
      this.quotation_path = this.file;
    },
    show: function (val, newVal) {
      this.dialog = this.show;
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    file: null,
    orderDetail: null,
  },
  created() {},
  components: {
    pdf,
  },
  data() {
    return {
      quotation_path: "",
      loading: false,
      dialog: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    generateQuote() {
      this.loading = true;
      let f = {
        order_id: this.order.order_id,
      };
      this.post("web/orders/download-quote", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.quotation_path = response.data.data;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    sendToCustomer() {
      this.$emit("sendToCustomer", this.quotation_path);
    },
    download() {
      window.document.location.href = this.quotation_path;
    },
  },
};
</script>