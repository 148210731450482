<template>
  <div>
    <v-card
      :loading="loading"
      class="mx-auto mt-3 mb-5 mr-5 order-list"
      height="390"
      max-height="400"
    >
      <template slot="progress">
        <v-progress-linear
          color="green"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-toolbar color="green" dark>
        <v-menu offset-y>
          <!--<template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>-->
          <v-list>
            <v-list-item v-for="(item, index) in items" link :key="index">
              <v-list-item-title @click="menuClick(item)"
                >{{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-toolbar-title
          >{{ title }}
          <span v-if="!empty(items)"
            >({{ items.length }})</span
          ></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>
      <!--<v-card-text>
        <v-chip-group
          v-model="selection"
          active-class="deep-purple accent-4 white--text"
          column
        >
          <v-chip>30 Mins</v-chip>
          <v-chip>1 Hour</v-chip>
          <v-chip>2 Hour</v-chip>
          <v-chip>3 Hours ></v-chip>
        </v-chip-group>
      </v-card-text>-->
      <v-list two-line height="320">
        <v-list-item-group v-model="selected" active-class="light-green--text">
          <template v-for="(item, index) in items">
            <v-list-item :key="item.title" @click="itemClick(item)">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title>
                    Order No: {{ item.order_id }} -
                    <span class="red--text"
                      ><strong>{{ item.delivery_time_slot }}</strong></span
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="item.full_name"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="item.contact_number"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="green-text">
                    {{
                      [item.duration * -1, "minutes"]
                        | duration("humanize", true)
                    }}

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <img
                          :src="'/static/images/' + item.channel_type + '.png'"
                          color="primary"
                          darks
                          v-on="{ ...tooltip }"
                        />
                      </template>
                      <span>{{ item.channel_type }}</span>
                    </v-tooltip>
                  </v-list-item-action-text>
                  <v-list-item-action-text class="green-text">
                    <v-chip class="white--text ml-0" color="green" label small>
                      {{ item.status }}
                    </v-chip>
                  </v-list-item-action-text>
                  <v-list-item-action-text class="green-text">
                    {{ item.user }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
    <order-detail
      :show="showOrderDetail"
      :order="selectedOrder"
      v-on:close="closed"
      :path="path"
      :permissions="permissions"
    ></order-detail>
  </div>
</template>
<script>
import OrderDetail from "./OrderDetail.vue";
export default {
  watch: {
    showOrderDetail: function (val, newVal) {
      this.$emit("showOrderDetail", this.showOrderDetail);
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: null,
    },
    permissions: null,
    path: null,
  },
  created() {},
  components: { OrderDetail },
  data() {
    return {
      loading: false,
      selected: [0],
      selection: null,
      showOrderDetail: false,
      selectedOrder: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    menuClick(item) {
      console.log(item);
    },
    getDuration(item) {
      return item.duration | duration("humanize");
      return [item.duration, "days"] | duration("humanize", true);
    },
    itemClick(item) {
      this.showOrderDetail = true;
      this.selectedOrder = item;
    },
    closed() {
      this.showOrderDetail = false;
      this.selectedOrder = null;
      this.$emit("statusChanged");
    },
  },
};
</script>