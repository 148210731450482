<template>
  <v-row>
    <v-flex xs12 sm12 md9 lg9 xl9>
      <v-row>
        <v-flex xs12 sm12 md3 lg3 xl3>
          <v-text-field
            v-model="orderDetail.full_name"
            label="Customer Name"
            readonly
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm12 md3 lg3 xl3>
          <v-text-field
            v-model="orderDetail.contact_number"
            label="Contact Number"
            readonly
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm12 md3 lg3 xl3>
          <v-text-field
            v-model="orderDetail.tendertype.tender_type"
            label="Payment Method"
            readonly
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm12 md3 lg3 xl3>
          <v-text-field
            v-model="orderDetail.status"
            label="Status"
            readonly
            required
          ></v-text-field>
        </v-flex>
      </v-row>
      <v-row>
        <v-flex xs12 sm12 md4 lg4 xl4>
          <v-text-field
            v-model="orderDetail.sales_channel"
            label="Sales Channel"
            readonly
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm12 md2 lg2 xl2>
          <v-text-field
            v-model="orderDetail.net_value"
            label="Order Value"
            readonly
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm12 md3 lg3 xl3>
          <v-text-field
            v-model="orderDetail.payment_slip"
            label="Payment Details"
            readonly
            required
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md2 lg2 xl2>
          <div
            class="v-input v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--light v-text-field v-text-field--is-booted"
          >
            <div class="v-input__control">
              <div class="v-input__slot">
                <div
                  class="v-text-field__slot pt-2 mr-2"
                  style="min-height: 32px"
                >
                  <label
                    for="input-213"
                    class="v-label v-label--active theme--light"
                    style="left: 0px; right: auto; position: absolute"
                    >Duration</label
                  >
                  {{
                    [orderDetail.duration * -1, "minutes"]
                      | duration("humanize", true)
                  }}
                </div>
              </div>
            </div>
          </div>
        </v-flex>
      </v-row>
    </v-flex>
    <v-flex xs12 sm12 md3 lg3 xl3>
      <v-row>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <div
            class="v-input v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--light v-text-field v-text-field--is-booted"
          >
            <div class="v-input__control">
              <div class="v-input__slot">
                <div class="v-text-field__slot">
                  <label
                    for="input-213"
                    class="v-label v-label--active theme--light"
                    style="left: 0px; right: auto; position: absolute"
                    >Delivery Address</label
                  >
                  {{ orderDetail.address.apartment }}<br />
                  {{ orderDetail.address.road }}<br />
                  {{ orderDetail.address.district }}<br />
                  {{ orderDetail.address.island }}<br />
                </div>
              </div>
            </div>
          </div>
        </v-flex>
      </v-row>
    </v-flex>
  </v-row>
</template>
<script>
export default {
  props: {
    orderDetail: null,
  },
};
</script>