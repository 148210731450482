<template>
  <v-row>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <div
        v-if="!empty(orderDetail)"
        class="v-input v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--light v-text-field v-text-field--is-booted"
      >
        <div class="v-input__control">
          <div class="v-input__slot">
            <div class="v-text-field__slot">
              <label
                for="input-213"
                class="v-label v-label--active theme--light"
                style="left: 0px; right: auto; position: absolute"
                >Item Details</label
              >
            </div>
          </div>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="orderDetail.items"
        :items-per-page="50"
        class="elevation-1 mt-3 hidden-md-and-down"
        v-model="selectedItems"
        item-key="id"
        show-select
      >
        <template v-slot:item.row_num="{ item }">
          {{ orderDetail.items.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.unit_price="{ item }">
          {{ item.unit_price.toFixed(2) }}
        </template>
        <template v-slot:item.net_value="{ item }">
          {{ item.net_value.toFixed(2) }}
        </template>
        <template v-slot:item.quantity="{ item }">
          <v-btn
            small
            elevation="0"
            color="white"
            @click="changeQuantity(orderDetail.items.indexOf(item))"
            >{{ item.quantity }}</v-btn
          >
        </template>
        <template v-slot:footer.prepend>
          <v-btn
            :disabled="
              orderDetail.status != 'Pending' &&
              orderDetail.status != 'Added to Basket'
            "
            elevation="0"
            color="green"
            @click="showAddItemScreen"
            >Add Items</v-btn
          >
        </template>
      </v-data-table>

      <v-data-table
        :headers="lessHeaders"
        :items="orderDetail.items"
        :items-per-page="50"
        class="elevation-1 mt-3 hidden-lg-and-up"
        v-model="selectedItems"
        item-key="id"
        show-select
      >
        <template v-slot:item.row_num="{ item }">
          {{ orderDetail.items.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.item_name="{ item }">
          {{ item.item_name }} <br />
          <v-btn
            small
            v-if="
              orderDetail.status == 'Pending' ||
              orderDetail.status == 'Added to Basked'
            "
            elevation="0"
            color="white"
            @click="changeQuantity(orderDetail.items.indexOf(item))"
            >{{ item.quantity }}</v-btn
          >
          <span v-else>{{ item.quantity }}</span>
          x {{ item.unit_price.toFixed(2) }} =
          {{ item.net_value.toFixed(2) }}
        </template>
        <template v-slot:footer.prepend>
          <v-btn
            :disabled="
              orderDetail.status != 'Pending' &&
              orderDetail.status != 'Added to Basket'
            "
            elevation="0"
            color="green"
            @click="showAddItemScreen"
            >Add Items</v-btn
          >
        </template>
      </v-data-table>
    </v-flex>
    <v-dialog
      v-model="showUpdateQuantity"
      hide-overlay
      persistent
      width="300"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="green" dark>
          <v-toolbar-title>Update Quantity</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="updateQuantity">
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
          <v-btn icon @click="showUpdateQuantity = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="layout align-center justify-center">
          <input-number v-model="quantity"></input-number>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddItem"
      hide-overlay
      persistent
      width="600"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="green" dark>
          <v-toolbar-title>Update Quantity</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="updateItem">
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
          <v-btn icon @click="showAddItem = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="layout align-center justify-center">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="search"
              :counter="10"
              :rules="nameRules"
              label="Item Name"
              @keyup.enter="searchForProducts()"
              required
            ></v-text-field>
            <v-data-table
              :headers="selectHeaders"
              :items="items"
              :items-per-page="20"
              class="elevation-0 mt-3"
              v-model="selectedItemToAdd"
              single-select
              item-key="item_no"
              show-select
            >
              <template v-slot:item.price="{ item }">
                {{ parseFloat(item.price).toFixed(2) }}
              </template>
            </v-data-table>

            <input-number
              v-model="form.quantity"
              label="Quantity"
            ></input-number>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import InputNumber from "../../components/Global/InputNumber.vue";
export default {
  components: { InputNumber },
  watch: {
    selectedItems: function (val, newVal) {
      this.$emit("itemSelectionChanged", this.selectedItems);
    },
  },
  methods: {
    updateStatus(status) {
      this.$emit("updateStatus", status, this.selectedItems);
    },
    changeQuantity(index) {
      this.quantity = this.orderDetail.items[index].quantity;
      this.selectedItem = index;
      this.showUpdateQuantity = true;
    },
    updateQuantity() {
      this.loading = true;
      let f = {
        item_id: this.orderDetail.items[this.selectedItem].id,
        order_id: this.orderDetail.id,
        quantity: this.quantity,
      };
      this.post("web/orders/update-quantity", f)
        .then((response) => {
          this.loading = false;
          if (response.data.status == true) {
            this.orderDetail.items[this.selectedItem].quantity = this.quantity;
            this.showUpdateQuantity = false;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    showAddItemScreen() {
      this.showAddItem = true;
    },
    updateItem() {
      if (
        this.empty(this.selectedItemToAdd) ||
        this.selectedItemToAdd.length <= 0
      ) {
        this.showError("Select at least one item to proceed");
        return;
      }
      let i = this.selectedItemToAdd[0];
      let f = {
        item_no: i.item_no,
        item_name: i.item_name,
        order_id: this.orderDetail.id,
        quantity: this.form.quantity,
      };
      this.loading = true;
      this.post("web/orders/add-items", f)
        .then((response) => {
          this.$emit("itemAdded", response.data.data);
          this.showAddItem = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
    searchForProducts() {
      let f = {
        search_value: this.search,
      };
      this.loading = true;
      this.post("web/orders/search-items", f)
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrors(error);
        });
    },
  },
  data() {
    return {
      quantity: 0,
      search: "",
      showUpdateQuantity: false,
      showAddItem: false,
      selectedItem: null,
      selectedItemToAdd: [],
      valid: false,
      headers: [
        { text: "#", value: "row_num" },
        { text: "Item No", value: "item_no" },
        { text: "Item Name", value: "item_name" },
        { text: "Quantity", value: "quantity", align: "end" },
        //{ text: "UOM", value: "uom_no" },
        { text: "Unit Price", value: "unit_price", align: "end" },
        { text: "Total", value: "net_value", align: "end" },
        { text: "Status", value: "status" },
      ],
      lessHeaders: [
        { text: "#", value: "row_num" },
        { text: "Item No", value: "item_no" },
        { text: "Item Name", value: "item_name" },
        //{ text: "Quantity", value: "quantity", align: "end" },
        //{ text: "UOM", value: "uom_no" },
        //{ text: "Unit Price", value: "unit_price", align: "end" },
        //{ text: "Total", value: "net_value", align: "end" },
        { text: "Status", value: "status" },
      ],
      selectHeaders: [
        { text: "Item No", value: "item_no" },
        { text: "Item Name", value: "item_name" },
        { text: "UOM", value: "uom" },
        { text: "Unit Price", value: "price", align: "end" },
      ],
      items: [],
      selectedItems: [],
      form: {
        id: "",
        item_no: "",
        item_name: "",
        quantity: 1,
        unit_price: 0,
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
    };
  },
  props: {
    orderDetail: null,
  },
};
</script>