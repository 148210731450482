<template>
  <div>
    <h4>Activity Log</h4>
    <v-timeline
      dense
      clipped
      color="green"
      v-if="!empty(orderDetail) && !empty(orderDetail.activitys)"
    >
      <v-timeline-item
        class="mb-4"
        small
        color="light-green"
        v-for="(activity, index) in orderDetail.activitys"
        :key="index"
      >
        <v-row justify="space-between">
          <v-col cols="7">
            <!--<v-chip
                          class="white--text ml-0"
                          color="purple"
                          label
                          small
                        >
                          APP
                        </v-chip>-->
            <v-chip
              class="white--text ml-0"
              color="light-green"
              label
              small
              @click="viewQuote(activity)"
              dark
              v-if="
                activity.activity.includes(
                  'Quotation Sent for Customer Approval'
                )
              "
            >
              Quote
            </v-chip>
            <v-chip
              class="white--text ml-0"
              color="green"
              label
              small
              @click="viewSlip(activity)"
              dark
              v-if="
                activity.activity.includes('Payment Slip Attachmed by Customer')
              "
            >
              Slip
            </v-chip>
            <div v-html="activity.activity"></div>
          </v-col>
          <v-col class="text-right" cols="5">
            {{
              [activity.duration * -1, "minutes"] | duration("humanize", true)
            }}
            <br />
            <v-chip class="white--text ml-0" color="green" label small>
              {{ activity.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>
<script>
export default {
  methods: {
    viewQuote(activity) {
      this.$emit("viewQuote", activity.additional_info);
    },
    viewSlip(activity) {
      console.log(activity);
      this.$emit("viewSlip", activity.additional_info);
    },
  },
  props: {
    orderDetail: null,
    drawer: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
